<template>
	<div class="informationdetail">
		<div class="informationdetail_mybreadcrumb">
				当前位置： <app-mybreadcrumb />
		</div>

		<div class="informationdetail_content">
			<div class="informationdetail_title">{{title}}</div>
			<div class="informationdetail_rich" v-html='rich'></div>

			<div class="informationdetail_down_list">
				<div class="informationdetail_down_item" v-for='item in file'>
					<div class="informationdetail_down_name">{{item.name}}</div>
					<div class="informationdetail_down_btn" @click='downFile(item)'>立即下载</div>
					<!-- <a class="informationdetail_down_btn" :href="item.id" download>立即下载</a> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MyBreadcrumb from '@/components/MyBreadcrumb/mybreadcrumb'

	export default {
		data() {
			return {
				rich: '',
				title: '',
				file: []
			}
		},
		components: {
			'app-mybreadcrumb': MyBreadcrumb
		},
		methods: {
			getData() {
				let data = {id: this.$route.query.ids}

				this.$get('/news_list_d', data).then(res => {
					if(res.data.result) {
						this.title = res.data.result.title
						this.rich = res.data.result.comment
						this.file = res.data.result.file
					}
				})
			},
			downFile(item) {
				let data = {id: item.id}
				location.href = `${this.baseUrl}/down_file?id=${item.id}`
				// this.$get('/down_file', data).then(res => {
				// 	let href = item.path
				//     location.href = href
				// })
			}
		},
		mounted() {
			this.getData()
		}
	}
</script>

<style scoped>
	@import './informationdetail.css';
</style>